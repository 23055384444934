.otp-input {
	display: flex;
	justify-content: center;
	input {
		width: 48px;
		height: 48px;
		border-radius: 4px;
		border: 2px solid #a3a3a3;
		color: #222;
		margin-left: 15px;
		text-align: center;
	}
}
