@import url(https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@200;300;400;500;600;700;800&family=Oswald:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html, body {
  height:100%;
}
body{font-family:'Be Vietnam Pro', sans-serif;font-weight:400}iframe{display:none !important}.flex{display:flex}.items-center{align-items:center}.f-oswald{font-family:'Oswald', sans-serif}.wrapper{background-color:#ffffff;padding:16px}.warning-color{color:#fa8c16}.cancel-color{color:#fc625d}.required-color{color:#e54d3e}.w-full{width:100%}.text-center{text-align:center}.cursor-pointer{cursor:pointer}.cursor-move{cursor:move}.ant-btn a{color:#ffffff}.hide{display:none}.color-767676{color:#767676}.ant-btn-primary{background-color:#1677ff !important}.ant-btn-primary:disabled{background-color:rgba(0,0,0,0.04) !important}.ant-btn-primary.ant-btn-background-ghost{background-color:transparent !important}.ant-switch{width:45px;background:rgba(0,0,0,0.25) !important}.ant-switch.ant-switch-checked{background:#4096ff !important}.ant-switch .ant-switch-inner{display:flex;align-items:center;justify-content:center;padding:0 5px}.ant-switch .ant-switch-inner span{display:flex;align-items:center;justify-content:center;margin-top:0 !important}@media only screen and (max-width: 768px){.body__sidebar{flex:0 0 40px !important;max-width:40px !important;min-width:40px !important;width:40px !important}.layout__body .container-fluid{padding:8px 4px}}.modal-total .ant-modal-title{text-align:center}

.layout__header{display:flex;align-items:center;padding-inline:20px}.layout__header .header__logo{display:flex}.layout__header .header__account-button{display:flex;justify-content:center;align-items:center;margin-left:auto;background-color:#22252b;border-color:#22252b;padding:20px 15px;border-radius:30px}.layout__header .header__account-button img{width:25px;height:25px;margin-right:10px}.layout__header .header__account-button strong{color:white;margin-right:10px}.layout__header .header__account-button .anticon{color:white}.layout__body{min-height:calc(100vh - 64px)}.layout__body .container{max-width:1200px;margin:0 auto;padding:30px 16px}.layout__body .container-fluid{padding:16px 10px}

.otp-input{display:flex;justify-content:center}.otp-input input{width:48px;height:48px;border-radius:4px;border:2px solid #a3a3a3;color:#222;margin-left:15px;text-align:center}

