.layout {
	&__header {
		display: flex;
		align-items: center;
		padding-inline: 20px;
		.header {
			&__logo {
				display: flex;
			}
			&__account-button {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-left: auto;
				background-color: #22252b;
				border-color: #22252b;
				padding: 20px 15px;
				border-radius: 30px;
				img {
					width: 25px;
					height: 25px;
					margin-right: 10px;
				}
				strong {
					color: white;
					margin-right: 10px;
				}
				.anticon {
					color: white;
				}
			}
		}
	}
	&__body {
		min-height: calc(100vh - 64px);
		.container {
			max-width: 1200px;
			margin: 0 auto;
			padding: 30px 16px;
		}
		.container-fluid {
			padding: 16px 10px;
		}
	}
}
