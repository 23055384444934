@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@200;300;400;500;600;700;800&family=Oswald:wght@400;500;600;700&display=swap');

body {
	font-family: 'Be Vietnam Pro', sans-serif;
	font-weight: 400;
}
iframe {
	display: none !important;
}
.flex {
	display: flex;
}
.items-center {
	align-items: center;
}
.f-oswald {
	font-family: 'Oswald', sans-serif;
}
.wrapper {
	background-color: #ffffff;
	padding: 16px;
}
.warning-color {
	color: #fa8c16;
}
.cancel-color {
	color: #fc625d;
}
.required-color {
	color: #e54d3e;
}
.w-full {
	width: 100%;
}
.text-center {
	text-align: center;
}
.cursor-pointer {
	cursor: pointer;
}
.cursor-move {
	cursor: move;
}
.ant-btn a {
	color: #ffffff;
}
.hide {
	display: none;
}
.color-767676 {
	color: #767676;
}
.ant-btn-primary {
	background-color: #1677ff !important;
	&:disabled {
		background-color: rgba(0, 0, 0, 0.04) !important;
	}
	&.ant-btn-background-ghost {
		background-color: transparent !important;
	}
}
.ant-switch {
	width: 45px;
	background: rgba(0, 0, 0, 0.25) !important;
	&.ant-switch-checked {
		background: #4096ff !important;
	}
	.ant-switch-inner {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 5px;
		span {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-top: 0 !important;
		}
	}
}
@media only screen and (max-width: 768px) {
	.body__sidebar {
		flex: 0 0 40px !important;
		max-width: 40px !important;
		min-width: 40px !important;
		width: 40px !important;
	}
	.layout__body .container-fluid {
		padding: 8px 4px;
	}
}

.modal-total {
	.ant-modal-title {
		text-align: center;
	}
}
